import {ResourceType} from 'api/resources/types';
import {USER_URL} from 'api/user/consts';

export const RESOURCE_URL_MAP: Record<ResourceType | 'configs' | 'user' | 'supervisors' | 'roles' | 'tags', string> = {
    [ResourceType.Skills]: 'v1/skills/values',
    [ResourceType.Absences]: 'v1/absence-types/values',
    [ResourceType.ProjectActivities]: 'v1/shift/event/values',
    [ResourceType.Schedules]: 'v1/schedule/types',
    [ResourceType.Departments]: 'internal/v1/departments',
    [ResourceType.BreakRules]: 'v1/break-rules/values',
    [ResourceType.Violations]: 'v1/shift-violation-types/suggest',
    // специально не правильно сделано
    [ResourceType.StaffAbsencesConfig]: 'WORKFORCE_MANAGEMENT_ABSENCE_GAP_MAPPING',
    supervisors: 'admin/v1/supervisors',
    roles: 'v1/access-groups/values',
    tags: 'admin/v1/tags',
    configs: 'v1/configs/values',
    user: USER_URL,
};
