import React from 'react';
import ReactDOMClient from 'react-dom/client';

import App from 'components/App';
import {syncAppPreloader} from 'utils/appPreloader';
import {RumLogger} from 'utils/rumLogger';
import 'antd/dist/reset.css';

import '@fullcalendar/react/dist/vdom';

syncAppPreloader();
RumLogger.init();

const container = document.getElementById('application');

if (container) {
    const root = ReactDOMClient.createRoot(container);

    root.render(<App/>);
} else {
    RumLogger.logError({
        message: 'Application container not found!',
        block: 'App',
        source: 'index',
        method: 'createRoot',
    }, new Error('Application container not found!'));
}
