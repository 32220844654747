import {AxiosResponse} from 'axios';

import {Actions} from 'utils/redux/flow/entity/types';

export const enum ResourceType {
    BreakRules = 'breakRules',
    Departments = 'departments',
    Schedules = 'schedules',
    Skills = 'skills',
    Absences = 'absences',
    ProjectActivities = 'projectActivities',
    Violations = 'violations',
    StaffAbsencesConfig = 'staffAbsencesConfig',
}

export type ResourcesConfigItemType = {
    type: ResourceType;
    required: boolean;
    cache: boolean;
    params?: ParamsType;
    ttl?: number;
}

export type ResourcesConfigType = ResourcesConfigItemType[];

export interface LoaderParam {
    fetcher: (args: unknown) => Promise<AxiosResponse>;
    actions: Actions;
}

export type LoaderParams = Record<ResourceType, LoaderParam>;

export type ParamsType = unknown[];

export interface ResourcesItemInterface {
    config: ResourcesConfigItemType;

    setForcedKey(key: string): void;
    load(): Promise<unknown>;
    delete(): void;
}

export interface ResourcesManagerInterface {
    loadResources(types: ResourceType[]): void;
    deleteResources(types: ResourceType[]): void;
    deleteCache(types: ResourceType[]): void;
    // validateConfig(config: ResourcesConfigType): undefined | never; // не понял, как описать интерфейс для статических членов класса
}

export type CacheItemType = {
    key: string;
    data: unknown;
    created_at: number;
    updated_at: number;
}
