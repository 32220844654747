import {createSelector} from 'reselect';

const baseSelector = (state: GlobalStateType) => state?.app?.config?.data?.configs;

export const versionSelector = createSelector(
    baseSelector,
    (state: GlobalStateType) => state.common.meta.item?.data?.version,
    (configs, version) => configs?.['wfm-frontend:version']?.version ?? version,
);

export const isApprovalsShiftsMultiselectEnabledSelector = createSelector(
    baseSelector,
    configs => configs?.['wfm-frontend:approvals-shifts-multiselect']?.enabled || false,
);

export const isForecastTemplatesBulkApplyEnabledSelector = createSelector(
    baseSelector,
    configs => configs?.['wfm-frontend:forecast-templates-bulk-apply']?.enabled || false,
);

export const maxDraftsProcessingSelector = createSelector(
    baseSelector,
    configs => configs?.['wfm-frontend:maximum-drafts-processing']?.maxCount || 0,
);

export const isViolationSettingsEnabledSelector = createSelector(
    baseSelector,
    configs => configs?.['wfm-frontend:violation-settings']?.enabled || false,
);

export const isV3PlanningEnabledSelector = createSelector(
    baseSelector,
    configs => configs?.['wfm-frontend:v3-planning']?.enabled || false,
);
