import '@yandex-int/rum-counter/dist/inline/interface';
import '@yandex-int/rum-counter/dist/inline/longtask';
import '@yandex-int/rum-counter/dist/bundle/scroll';
import '@yandex-int/rum-counter/dist/bundle/send';
import '@yandex-int/rum-counter/dist/bundle/inp';
import '@yandex-int/rum-counter/dist/bundle/onload';
import '@yandex-int/rum-counter/dist/bundle/retries';
// import '@yandex-int/rum-counter/debug/blockstat.js';
import '@yandex-int/error-counter/dist/interfaceOverRum';
import '@yandex-int/error-counter/dist/implementation';
import '@yandex-int/error-counter/dist/filters';
import '@yandex-int/error-counter/dist/logError';
import '@yandex-int/error-counter/dist/logAjaxError';

import ConfigApi from 'api/config';
import {cookies} from 'utils/cookies';

type ErrorOptions = Parameters<typeof window['Ya']['Rum']['logError']>;

export class RumLogger {
    private static limits = {
        client: 20,
        uncaught: 20,
        script: 20,
        external: 20,
    };

    private static silent = {
        client: 10,
        uncaught: 10,
        script: 10,
        external: 10,
    };

    private static subPage: any = undefined;

    private static env: Nullable<Ya.Rum.LogErrorInitSettings['env']> = null;

    public static init = async () => {
        const projectInfo = await RumLogger.getProjectInfo();

        window?.Ya?.Rum?.init({
            beacon: true,
            clck: 'https://yandex.ru/clck/click',
            sendUrlInResTiming: true,
            sendAutoResTiming: true,
            reqid: cookies.get('Session_id'),
        }, {
            'rum_id': 'ru.taxi-frontend-wfm',
            '-project': 'taxi-frontend-wfm',
            '-page': window.location.pathname,
            '-env': projectInfo.env,
            '-platform': 'desktop',
            '-uid': cookies.get('Session_id') || '',
            // '-version': version || '',
        });

        // @ts-expect-error import required
        import('@yandex-int/rum-counter/dist/bundle/implementation');

        window?.Ya?.Rum?.initErrors(projectInfo);
        // window?.Ya?.Rum?.debug?.();
    };

    /*
    * Основные поля, которые можно указывать:
    * https://github.yandex-team.ru/rum/error-counter#отправка-кастомной-ошибки
    *   message: 'parse data',
        block: 'stream',
        method: '_getData',
        source: 'ugc_backend',
        sourceMethod: 'set_reactions',
        type: 'logic',
        page: 'product'
    *
    * */
    public static logError = (options: Omit<ErrorOptions[0], 'service'>, error: ErrorOptions[1]) => {
        const fullOptions: ErrorOptions[0] = {
            page: options.page || 'common',
            type: options.type || 'logic',
            service: 'wfm',
            level: options.level || Ya.Rum.ERROR_LEVEL.ERROR,
            additional: {
                ...RumLogger.getProjectInfo(),
                meta: options.additional,
            },
        };

        console.info('[rum]: log error', {optionsFromRun: options, fullOptions});

        // https://github.yandex-team.ru/rum/error-counter#отправка-кастомной-ошибки
        window?.Ya?.Rum?.logError(fullOptions, error);
    };

    public static startSubPage = (subPageName: string) => {
        try {
            if (RumLogger.subPage) {
                RumLogger.subPage.cancel();
            }

            RumLogger.subPage = window?.Ya?.Rum?.makeSubPage?.(subPageName);

            const start = window?.Ya?.Rum?.getTime?.();

            // tti для subPage
            // @ts-expect-error too many window type re-declarations
            window?.Ya?.Rum?.sendTTI?.(false, start, RumLogger.subPage);
        } catch (e) {
            console.error(e);
        }
    };

    public static sendFirstPaintSubPage = () => {
        try {
            window?.Ya?.Rum?.sendTimeMark?.('2793', window?.Ya?.Rum?.getTime?.(), false, RumLogger.subPage);
        } catch (e) {
            console.error(e);
        }
    };

    public static cancelSubPage = () => {
        if (RumLogger.subPage) {
            RumLogger.subPage.cancel?.();
        }
    };

    public static startTimer = (id: string) => {
        try {
            window?.Ya?.Rum?.time?.(id);
        } catch (e) {
            console.error(e);
        }
    };

    public static stopTimer = (timerId: string) => {
        try {
            window?.Ya?.Rum?.timeEnd?.(timerId);
        } catch (e) {
            console.error(e);
        }
    };

    public static stopAndSendTimer = (timerId: string, timeMarkId: string) => {
        try {
            window?.Ya?.Rum?.timeEnd?.(timerId);

            window?.Ya?.Rum?.sendTimeMark(timeMarkId);
        } catch (e) {
            console.error(e);
        }
    };

    private static getEnvironmentInfo = async () => {
        if (RumLogger.env !== null) {
            return RumLogger.env;
        }

        try {
            const configResponse = await ConfigApi.remote.load();

            RumLogger.env = configResponse.data.env;

            return RumLogger.env;
        } catch (e) {
            console.error(e);

            return null;
        }
    };

    private static getProjectInfo = async () => {
        const env = await RumLogger.getEnvironmentInfo();

        return {
            project: 'wfm',
            env: env || 'development',
            limits: RumLogger.limits,
            silent: RumLogger.silent,
            debug: env !== 'production',
        };
    };
}
