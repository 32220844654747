import {Saga} from 'redux-saga';
import {fork, delay} from 'typed-redux-saga';

import {sagaMiddleware} from 'reduxStore';
import {getErrorMessage} from 'utils/axios';
import {RumLogger} from 'utils/rumLogger';

import {LoadQueueItemType, TasksMapType, LoadedTasksCountsMapType} from './types';

export function runFork(effect: Saga) {
    return function * () {
        yield * fork(effect);
    };
}

// нужно для параллельной работы разных экземпляров компонентов
// завязанных на одну сагу
export const LOADED_TASKS: TasksMapType = {};

export const testSaga = function * () {
    yield * delay(10);
};

export function loadTask(loadQueueItem: LoadQueueItemType) {
    try {
        if (LOADED_TASKS[loadQueueItem.loadId]?.count > 0) {
            LOADED_TASKS[loadQueueItem.loadId].count++;
        } else {
            LOADED_TASKS[loadQueueItem.loadId] = {
                options: loadQueueItem,
                task: sagaMiddleware.run(loadQueueItem.saga, loadQueueItem.args),
                count: 1,
                isRoot: loadQueueItem.isRoot,
            };
        }
    } catch (e) {
        const {message} = getErrorMessage(e);

        RumLogger.logError({
            message: 'Error in withSaga\'s utils - loadTask',
            block: 'withSaga',
            source: '/',
            method: 'loadTask',
            additional: {loadedTasks: LOADED_TASKS},
        }, new Error(message));
    }
}

export function disposeTask(loadId: string) {
    try {
        if (LOADED_TASKS[loadId]?.count > 1) {
            LOADED_TASKS[loadId].count--;
            return;
        }
        if (LOADED_TASKS[loadId]?.count === 1) {
            LOADED_TASKS[loadId].task?.cancel();
            delete LOADED_TASKS[loadId];
        }
        // При разрушении раздела функция disposeTasksOnPage, которая находиться в withPage выполняется раньше,
        // и в таком случае функции disposeTask, которые вызываются при разрешении компонентов добавленных на этой странице,
        // вызаваются после disposeTasksOnPage и пытаются удалить саги, которые уже удалены.
    } catch (e) {
        const {message} = getErrorMessage(e);

        RumLogger.logError({
            message: 'Error in withSaga\'s utils - disposeTask',
            block: 'withSaga',
            source: '/',
            method: 'disposeTask',
            additional: {loadId, loadedTasks: LOADED_TASKS},
        }, new Error(message));
    }
}

export function disposeTasksOnPage() {
    try {
        Object.keys(LOADED_TASKS).forEach(loadId => {
            if (!LOADED_TASKS[loadId]?.isRoot) {
                LOADED_TASKS[loadId].task?.cancel();
                delete LOADED_TASKS[loadId];
            }
        });
    } catch (e) {
        const {message} = getErrorMessage(e);

        RumLogger.logError({
            message: 'Error in withSaga\'s utils - disposeTasksOnPage',
            block: 'withSaga',
            source: '/',
            method: 'disposeTasksOnPage',
            additional: {loadedTasks: LOADED_TASKS},
        }, new Error(message));
    }
}

export const getLoadedTasksCountsMap: () => LoadedTasksCountsMapType = () => Object.keys(LOADED_TASKS).reduce((result, loadId) => {
    result[loadId] = LOADED_TASKS[loadId]?.count;

    return result;
}, {} as LoadedTasksCountsMapType);

export const clearLoadedTasks = () => {
    Object.keys(LOADED_TASKS).map(key => {
        delete LOADED_TASKS[key];
    });
};

export const getDisplayName = (Component: React.ComponentType<any>) => Component.displayName || Component.name || 'Component';
