import {notification} from 'antd';
import {isEmpty} from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import {call, put, take, all, fork, takeEvery, select} from 'typed-redux-saga';

import SettingsApi from 'api/app/settings';
import ConfigApi from 'api/config';
import {ConfigsAPI} from 'api/configs';
import {UsersAPI} from 'api/user';
import {actions as appActions} from 'reducers/app';
import {bound as lsActions} from 'reducers/common/localStorage/actions';
import {actions as metaActions} from 'reducers/common/meta';
import {actions as domainsResourceActions} from 'reducers/resources/domains';
import appTimezoneSelector from 'selectors/app/settings/appTimezoneSelector';
import {makeLocalStorageSelector} from 'selectors/common/localStorage';
import {ConfigName, WFMFrontendDomains} from 'types/api/backend-py3/definitions/configs';
import {Domains, DomainsOptions} from 'types/domains';
import {getErrorMessage} from 'utils/axios';
import {CookieName, cookies, setNewDomain} from 'utils/cookies';
import i18n from 'utils/localization/i18n';
import metrika from 'utils/metrika';
import {pushWithQuery} from 'utils/router';
import {Queries} from 'utils/searchParams/queries';
import {
    extractAvailableDomains,
    extractPermissions,
} from 'utils/user';

// Если у юзера был выставлен домен, который потом стал для него недоступным, то нужно его сбросить
function * checkDomain(availableDomains: string[], currentDomain: string) {
    if (!isEmpty(availableDomains) && !availableDomains.includes(currentDomain)) {
        setNewDomain(availableDomains[0] as Domains);
        lsActions.set({key: 'domain', data: availableDomains[0]});
        window.location.reload();
    }
}

function sendMetrika() {
    const language = cookies.get(CookieName.Language);

    if (language) {
        metrika.reachGoal('language', {language});
    }
}

function * setDomainFromParam() {
    const searchParams = new URLSearchParams(window.location.search);
    const domain = searchParams.get(Queries.Domain);

    if (domain) {
        setNewDomain(domain as Domains);
        lsActions.set({key: 'domain', data: domain});
        pushWithQuery(window.location.pathname, {domain: ''});
        window.location.reload();
    }
}

function * handleAppPreloadRequest() {
    try {
        const {data: configuration} = yield * call(ConfigApi.remote.load);

        yield * put(appActions.successConfig(configuration));

        const meta = yield * call(ConfigApi.remote.loadMeta);

        yield * put(metaActions.success(meta.data));

        const currentDomain = yield * select(makeLocalStorageSelector<string>('domain'));

        const response = yield * call(UsersAPI.request);

        const availableDomains = yield * call(extractAvailableDomains, response.data);
        const permissions = yield * call(extractPermissions, response.data, currentDomain);

        yield * call(setDomainFromParam);
        yield * call(checkDomain, availableDomains, currentDomain);
        yield * put(appActions.successUser({domains: availableDomains, permissions: permissions}));

        const domainsResponse = yield * call(ConfigsAPI.request, ['WFM_FRONTEND_DOMAINS'] as ConfigName[]);

        if (!domainsResponse.data.configs) {
            throw new Error(i18n.print('common.errors.cannot_load_config'));
        }

        const domainsConfig = domainsResponse.data.configs[0] as WFMFrontendDomains;

        yield * put(domainsResourceActions.success(domainsConfig.body as DomainsOptions));

        sendMetrika();
    } catch (e) {
        const {message: errorDescription, config} = getErrorMessage(e);
        let errorMessage;

        if (config?.url === '/api/config') {
            errorMessage = i18n.print('common.errors.cannot_load_config');
            yield * put(appActions.failConfig(errorDescription));

            notification.error({
                message: errorMessage,
                description: errorDescription,
                duration: 0,
            });
        }
        // пока не надо, т.к. нотификация уже стреляет в интерцепторе
        // else if (e.config.url === UserApi.consts.USER_URL) {
        //     errorMessage = CommonMessage({id: 'ERRORS.CANNOT_LOAD_USER'});
        // }
    }
}

function * appSagaRequestHandler() {
    yield * all([
        take(appActions.requestConfig),
        take(appActions.requestUser),
    ]);

    yield * fork(handleAppPreloadRequest);
}

function * timezonesSaga() {
    const timezone = yield * select(appTimezoneSelector);
    const oldSettings = SettingsApi.getSettings();
    const newSettings = cloneDeep(oldSettings);

    newSettings[SettingsApi.TIMEZONE_KEY] = timezone;

    SettingsApi.storeSettings(newSettings);
    SettingsApi.applyChanges();
}

function * appSettingsHandler() {
    yield * takeEvery([appActions.setTimezone, appActions.resetTimezone], timezonesSaga);
}

function * appSaga() {
    yield * fork(appSagaRequestHandler);
    yield * fork(appSettingsHandler);

    yield * put(appActions.requestConfig());
    yield * put(appActions.requestUser());
}

export default appSaga;
