import {push} from 'redux-first-history';

import {actions as routerActions} from 'reducers/router';
import store from 'reduxStore';
import {searchParamsCompat} from 'utils/searchParams/backwardCompat';
import {isArray} from 'utils/typescript/typeguards';

type PushParams = Parameters<typeof push>;

export interface PushWithQueryOptions<S = any> {
    clearAll?: boolean;
    state?: S;
}

type QueryType = Record<string, Empty<string | string[]>>;

export function prepareQuery(query: QueryType, source?: URLSearchParams): URLSearchParams {
    return Object.keys(query).reduce<URLSearchParams>((result, key) => {
        const value = query[key];

        if (value === null || value === undefined || value === '') {
            result.delete(key);

            return result;
        }

        if (isArray(value)) {
            // Replace previous value
            result.delete(key);

            value.forEach(v => result.append(key, v));

            return result;
        }

        result.set(key, value);

        return result;
    }, new URLSearchParams(source));
}

export function preparePushWithQueryData<S = any>(
    pathname: string,
    query?: QueryType,
    options?: PushWithQueryOptions<S>,
): PushParams[0] | string {
    if (!query) {
        return pathname;
    }

    const {clearAll = false, state} = options || {};

    if (clearAll) {
        return {
            pathname,
            search: `?${prepareQuery(query).toString()}`,
            state,
        };
    }

    const currentQuery = searchParamsCompat(window.location.search);

    return {
        pathname,
        search: `?${prepareQuery(query, currentQuery).toString()}`,
        state,
    };
}

export const prepareHrefWithQuery = (
    pathname: string,
    query?: QueryType,
    options?: Pick<PushWithQueryOptions, 'clearAll'>,
) => {
    const {search} = preparePushWithQueryData(pathname, query, options);

    return window.location.origin + pathname + search;
};

export function pushWithQuery<S = any>(
    pathname: string,
    query?: QueryType,
    options?: PushWithQueryOptions<S>,
) {
    store.dispatch(routerActions.push(preparePushWithQueryData(pathname, query, options) as PushParams[0]));
}
