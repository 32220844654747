import {ResourceType} from 'api/resources';
import {RESOURCE_URL_MAP} from 'utils/httpApi/callCenterApi/consts';
import {RumLogger} from 'utils/rumLogger';

export const findAndStopRumTimer = (url?: string, timerKey?: string) => {
    if (!url || !timerKey) {
        return;
    }

    // Так как некоторые запросы могут содержать в качестве параметра домен
    const resourceKey = Object.keys(RESOURCE_URL_MAP).find(key =>
        url?.includes(RESOURCE_URL_MAP[key as ResourceType])) as ResourceType || undefined;

    if (!!resourceKey && !!timerKey) {
        RumLogger.stopAndSendTimer(resourceKey, resourceKey);
    }
};
